<single-column [terms]="false" [talk]="false">
    <h1 class="text-center sprk-b-TypeDisplayOne">Talk to Us</h1>
    <p class="text-center">Support for the following companies can be found here.</p>

    <logo-block></logo-block>

    <div class="bgGrey" *ngIf="!ttuFlagIsActive" >
        <label>Hours Of Operation</label>
        <p><b>Mon - Fri:</b> 8:30 a.m. – 9:00 p.m. ET<br>
            <b>Sat:</b> 9:00 a.m. - 4:00 p.m. ET</p>

        <label>Email</label>
        <p>Please take a moment to determine which email address best suits your needs.</p>
        <p>If you have a <b>new loan</b>, a <b>loan in process</b>, are a <b>Rocket Money account holder</b>, or have any
        <b>other concerns</b>, reach out to:</p>
        <p><a href="mailto:support@rocketaccount.com">Support@rocketaccount.com</a></p>
        <p>If you're currently a <b>serviced client</b> and make <b>monthly mortgage payments</b>, reach out to:</p>
        <p><a href="mailto:supportservicing@rocketaccount.com">SupportServicing@rocketaccount.com</a></p>

        <label>Toll Free</label>
        <p class="visible-xs"><a href="tel:8552079072">(855) 207-9072</a></p>
        <p class="hidden-xs">(855) 207-9072</p>
    </div>
    <div class="bgGrey" *ngIf="ttuFlagIsActive">
      <label>Hours Of Operation</label>
      <p><b>Mon - Fri:</b> 8:30 a.m. – 9:00 p.m. ET<br>
          <b>Sat:</b> 9:00 a.m. - 4:00 p.m. ET</p>

      <label>Email</label>
      <p>Please take a moment to determine which email address best suits your needs.</p>
      <p>If you have a <b>new loan</b>, a <b>loan in process</b>, or have any
      <b>other concerns</b>, reach out to:</p>
      <p><a href="mailto:support@rocketaccount.com">Support@rocketaccount.com</a></p>
      <p>If you're currently a <b>serviced client</b> and make <b>monthly mortgage payments</b>, reach out to:</p>
      <p><a href="mailto:supportservicing@rocketaccount.com">SupportServicing@rocketaccount.com</a></p>

      <label>Toll Free</label>
      <p class="visible-xs"><a href="tel:8552079072">(855) 207-9072</a></p>
      <p class="hidden-xs">(855) 207-9072</p>

      <p><b>For Rocket Money customers, please use the following methods to reach our support team:</b></p>
      <label>Email</label>
      <p><a href="mailto:support@rocketmoney.com">Support@rocketmoney.com</a></p>
      <label>Chat</label>
      <p>Please visit our <a href="https://help.rocketmoney.com/en/articles/2684134-contacting-rocket-money" target="_blank">Contacting Rocket Money</a>
           page to chat with support.</p>
      <label>Hours Of Operation</label>
      <p><b>Mon - Fri:</b> 9:00 a.m. – 8:00 p.m. ET<br>
    </div>

    <div class="embeddedServiceHelpButton">chat</div>

    <p *ngIf="src?.toLowerCase()!==pageSource.MFA" id="go-back-link" class="text-center"><b><a (click)="goBack()"><i class="chevron left"></i> Go Back</a></b></p>

</single-column>
